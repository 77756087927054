import { Rule as AntRule } from 'antd/es/form';
import {
  FormListFieldData as AntFormListFieldData,
  FormListOperation as AntFormListOperation,
} from 'antd/es/form/FormList';
import { FormItemInputContext } from 'antd/es/form/context';
import { FormItemLabelProps } from 'antd/lib/form/FormItemLabel';
import { FieldProps } from 'rc-field-form/lib/Field';
import {
  FieldData as RcFieldData,
  NamePath as RcNamePath,
  FieldError as RcFieldError,
} from 'rc-field-form/lib/interface';

export type { FormInstance as UiFormInstance } from 'antd/es/form';
export { UiForm } from './UiForm';
export type { UiFormComposition, UiFormProps } from './UiForm';

export type UiFieldNamePath = RcNamePath;
export type UiFieldError = RcFieldError;
export type UiFieldData = RcFieldData;
export type UiFieldProps = FieldProps;
export type UiFormItemLabelProps = FormItemLabelProps;
export type UiFormListOperation = AntFormListOperation;
export type Rule = AntRule;
export type UiFormListFieldData = AntFormListFieldData;
export type UiFormListFieldDataItem = Omit<UiFormListFieldData, 'key'>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UiFormListItemAddActionType = (defaultValue?: any, insertIndex?: number) => void;
export type UiFormListItemRemoveActionType = (index: number | number[]) => void;
export type UiFormListItemMoveActionType = (from: number, to: number) => void;

export interface UiFormItemProps<T = unknown> {
  id?: string;
  value?: T;
  onChange?: (value?: T) => void;
}

export const UiFormItemInputContext = FormItemInputContext;
