import classNames from 'classnames';
import React, { CSSProperties, FC, HTMLAttributes, PropsWithChildren, ReactNode, useMemo } from 'react';

import styles from './UiOverlay.scss';
import { UiOverlayButton } from './button';

export type UiOverlayTrigger = 'hover';
export type UiOverlayBehavior = 'appearance' | 'scale';
export enum UiOverlayTypes {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

enum StructuralElement {
  Header = 'header',
  Main = 'main',
  Footer = 'footer',
}

interface StructuredContent {
  [StructuralElement.Header]?: ReactNode;
  [StructuralElement.Main]?: ReactNode;
  [StructuralElement.Footer]?: ReactNode;
}

export interface UiOverlayProps extends Omit<HTMLAttributes<HTMLDivElement>, 'content'> {
  type?: UiOverlayTypes;
  content?: ReactNode | StructuredContent;
  overlayStyles?: CSSProperties;
  trigger?: UiOverlayTrigger;
  behavior?: UiOverlayBehavior;
  isVisible?: boolean;
  isFixedHeight?: boolean;
}

type UiOverlayComposition = {
  Button: typeof UiOverlayButton;
};

type UiOverlayComponent = FC<PropsWithChildren<UiOverlayProps>> & UiOverlayComposition;

export const UiOverlay: UiOverlayComponent = (props) => {
  const {
    content,
    children,
    className,
    overlayStyles,
    trigger,
    behavior = 'appearance',
    type = UiOverlayTypes.Primary,
    isVisible = true,
    isFixedHeight,
    ...restProps
  } = props;

  const isStructuredContent = React.isValidElement(content)
    ? false
    : Boolean(content) &&
      Object.values(StructuralElement).some((key) => key in (content as StructuredContent));

  const renderContent = useMemo((): ReactNode => {
    if (!content) {
      return null;
    }

    if (isStructuredContent) {
      const structuredContent = content as StructuredContent;

      return (
        <>
          {structuredContent[StructuralElement.Header] && (
            <div className={styles.uiOverlay__contentHeader}>
              {structuredContent[StructuralElement.Header]}
            </div>
          )}
          {structuredContent[StructuralElement.Main] && (
            <div className={styles.uiOverlay__contentMain}>{structuredContent[StructuralElement.Main]}</div>
          )}
          {structuredContent[StructuralElement.Footer] && (
            <div className={styles.uiOverlay__contentFooter}>
              {structuredContent[StructuralElement.Footer]}
            </div>
          )}
        </>
      );
    }

    return content as ReactNode;
  }, [content, isStructuredContent]);

  return (
    <div
      {...restProps}
      className={classNames(styles.uiOverlay, className, {
        [`${styles.uiOverlay}_${trigger}`]: trigger,
        [`${styles.uiOverlay}_${behavior}`]: trigger,
      })}
    >
      <div className={styles.uiOverlay__overlayed}>{children}</div>
      <div
        className={classNames(styles.uiOverlay__overlay, styles.uiOverlay__overlayDefault, {
          [`${styles.uiOverlay__overlay}_${type}`]: type,
          [styles.uiOverlay__overlay_heightFixed]: isFixedHeight,
          [styles.uiOverlay__overlay_visible]: isVisible,
        })}
        style={overlayStyles}
      />
      {content && (
        <div
          className={classNames(styles.uiOverlay__content, {
            [styles.uiOverlay__content_structured]: isStructuredContent,
          })}
        >
          {renderContent}
        </div>
      )}
    </div>
  );
};

UiOverlay.Button = UiOverlayButton;
