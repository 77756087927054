// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c8342e9c_ui-typography-footnote{font-weight:600;font-size:13px;line-height:14px}", "",{"version":3,"sources":["webpack://./../../libs/ui/src/components/typography/footnote/UiTypographyFootnote.scss","webpack://./../../libs/ui/src/assets/scss/mixins/typography.scss"],"names":[],"mappings":"AACA,iCCyEE,eAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":["@import \"/app/libs/ui/src/assets/scss/index.scss\";\n.ui-typography-footnote {\n  @include fontFootnote;\n}\n","// Headings\n@mixin fontH0 {\n  font-weight: 700;\n  font-size: 38px;\n  line-height: 46px;\n}\n\n@mixin fontH1 {\n  font-weight: 700;\n  font-size: 32px;\n  line-height: 36px;\n}\n\n@mixin fontH2 {\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 32px;\n}\n\n@mixin fontH3 {\n  font-weight: 700;\n  font-size: 20px;\n  line-height: 24px;\n  letter-spacing: 0.1px;\n}\n\n@mixin fontH4 {\n  font-weight: 700;\n  font-size: 17px;\n  line-height: 20px;\n  letter-spacing: 0.1px;\n}\n\n@mixin fontH5 {\n  font-weight: 700;\n  font-size: 15px;\n  line-height: 20px;\n  letter-spacing: 0.1px;\n}\n\n// Interface\n@mixin fontBody {\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  letter-spacing: 0.05px;\n}\n\n@mixin fontParagraph {\n  font-weight: 400;\n  font-size: 15px;\n  line-height: 20px;\n}\n\n@mixin fontTitle {\n  font-weight: 600;\n  font-size: 15px;\n  line-height: 20px;\n  letter-spacing: 0.1px;\n}\n\n@mixin fontBadge {\n  font-weight: 600;\n  font-size: 11px;\n  line-height: 12px;\n}\n\n@mixin fontTag {\n  font-weight: 700;\n  font-size: 12px;\n  line-height: 20px;\n}\n\n@mixin fontFootnote {\n  font-weight: 600;\n  font-size: 13px;\n  line-height: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui-typography-footnote": "c8342e9c_ui-typography-footnote",
	"uiTypographyFootnote": "c8342e9c_ui-typography-footnote"
};
export default ___CSS_LOADER_EXPORT___;
