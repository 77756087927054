import { CheckCircleOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { notification as antNotification, NotificationArgsProps } from 'antd';
import React, { CSSProperties, ReactElement } from 'react';

import CloseSVG from '@vkph/ui/svg/close.svg';
import ThunderSvg from '@vkph/ui/svg/thunder.svg';

import { variables } from '../../providers/theme';
import { UiIcon } from '../icon';
import { UiTypography } from '../typography';
import styles from './Notification.scss';

type notificationTypes = 'error' | 'success' | 'warning' | 'info';

const notificationIcons: Record<notificationTypes, ReactElement> = {
  success: <CheckCircleOutlined />,
  error: <ExclamationCircleOutlined />,
  warning: <UiIcon component={ThunderSvg} />,
  info: <InfoCircleOutlined />,
};

export interface notificationParams extends Pick<NotificationArgsProps, 'duration' | 'placement'> {
  message: string;
  description?: string;
}

const createNotificationMethod = (type: notificationTypes) => {
  return ({ message, description, placement = 'topRight', duration = 3 }: notificationParams) => {
    const icon = notificationIcons[type];
    const style: CSSProperties = { marginBottom: 0 };
    const topOffset = variables.themeOffsets.modalOffset + variables.spaceSizes.spaceXL;

    antNotification.config({
      top: topOffset,
    });

    antNotification[type]({
      message: (
        <UiTypography.Title level={5} style={style} className={styles[`ui-notification_${type}`]}>
          {message}
        </UiTypography.Title>
      ),
      description: <UiTypography.Text style={style}>{description}</UiTypography.Text>,
      placement,
      duration,
      className: styles[`ui-notification_${type}`],
      icon,
      closeIcon: <UiIcon component={CloseSVG} width={20} height={20} />,
    });
  };
};

export const notification = {
  info: createNotificationMethod('info'),
  success: createNotificationMethod('success'),
  error: createNotificationMethod('error'),
  warning: createNotificationMethod('warning'),
};
