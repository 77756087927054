import isObject from 'lodash/isObject';
import { ImagePreviewType as AntImagePreviewType } from 'rc-image/lib/Image';
import React, { FC, PropsWithChildren, ReactNode, useMemo } from 'react';

import { UiImageProps } from '../UiImage';
import { UiImagePreviewToolbar, UiImagePreviewToolbarExtension, PREVIEW_TRANSFORM_SCALE } from './toolbar';

export interface UiImagePreviewPropExtended extends AntImagePreviewType, UiImagePreviewToolbarExtension {}

interface UiImagePreviewProps extends Pick<UiImageProps, 'wrapperStyle'> {
  preview: UiImagePreviewPropExtended | true;
}
type UiImagePreviewComponent = FC<PropsWithChildren<UiImagePreviewProps>>;

export const UiImagePreview: UiImagePreviewComponent = (props) => {
  const { preview, children } = props;
  const { customize, toolbarRender, ...restPreviewConfig }: UiImagePreviewPropExtended = isObject(preview)
    ? preview
    : {};

  const getDefaultToolbar: AntImagePreviewType['toolbarRender'] = (_, { transform, actions }) => (
    <UiImagePreviewToolbar transform={transform} actions={actions} customize={customize} />
  );

  const previewConfig = useMemo<UiImagePreviewPropExtended>(() => {
    return {
      toolbarRender: toolbarRender || getDefaultToolbar,
      mask: <div style={{ cursor: 'pointer' }} />,
      scaleStep: PREVIEW_TRANSFORM_SCALE.SCALE_STEP,
      minScale: PREVIEW_TRANSFORM_SCALE.SCALE_MIN,
      maxScale: PREVIEW_TRANSFORM_SCALE.SCALE_MAX,
      closeIcon: null,
      ...restPreviewConfig,
    };
  }, []);

  return (
    <>
      {React.Children.map<ReactNode, ReactNode>(children, (child) => {
        if (React.isValidElement<UiImageProps>(child)) {
          return React.cloneElement(child, { preview: previewConfig });
        }

        return null;
      })}
    </>
  );
};
