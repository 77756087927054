import React, { FC, PropsWithChildren, useMemo, useState } from 'react';

import {
  ToggleButtonGroupContext,
  ToggleButtonGroupContextValue,
  ToggleButtonGroupValue,
  ToggleButtonParams,
} from '../context';

export interface UiToggleButtonGroupProps
  extends Pick<ToggleButtonGroupContextValue, 'onChange' | 'value'>,
    PropsWithChildren,
    ToggleButtonParams {}

export const UiToggleButtonGroup: FC<UiToggleButtonGroupProps> = (props) => {
  const { children, onChange, value: customizedValue, ...restParams } = props;
  const [value, setValue] = useState<ToggleButtonGroupValue>(customizedValue);

  const onChangeValue: UiToggleButtonGroupProps['onChange'] = (val, event) => {
    setValue(val);
    onChange?.(val, event);
  };

  const contextValue = useMemo<ToggleButtonGroupContextValue>(
    () => ({ value, onChange: onChangeValue, toggleButtonParams: restParams }),
    [value, onChangeValue],
  );

  return (
    <ToggleButtonGroupContext.Provider value={contextValue}>{children}</ToggleButtonGroupContext.Provider>
  );
};
