import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import React, { FC, useContext } from 'react';

import CalendarSvg from '@vkph/ui/svg/calendar.svg';

import { UiConfigContext } from '../config-provider';
import { UiDatePickerClearIcon } from './clear-icon/UiDatePickerClearIcon';
import { DateFnsPicker } from './date-fns-picker/DateFnsPicker';
import { UiDatePickerOkButton } from './ok-button/UiDatePickerOkButton';

export type UiDatePickerFormatType = 'date' | 'dateTime' | 'dateYear';
export type UiDatePickerFormatTypeProps = {
  formatType?: UiDatePickerFormatType;
};
export interface UiDatePickerProps extends Omit<PickerProps, 'showNow'>, UiDatePickerFormatTypeProps {}

const datePickerFormatProps: Record<UiDatePickerFormatType, PickerProps> = {
  date: {
    showTime: false,
    format: 'dd.MM.yyyy',
    placeholder: 'Укажите дату',
  },
  dateTime: {
    format: 'dd.MM.yyyy HH:mm',
    showTime: { format: 'HH:mm' },
    placeholder: 'Укажите дату и время',
  },
  dateYear: {
    picker: 'year',
    format: 'yyyy',
    placeholder: 'Укажите год',
  },
};

export const UiDatePicker: FC<UiDatePickerProps> = (props) => {
  const { formatType = 'date', ...restProps } = props;
  const { getPopupContainer } = useContext(UiConfigContext);

  return (
    <DateFnsPicker
      suffixIcon={<CalendarSvg />}
      allowClear={{ clearIcon: UiDatePickerClearIcon }}
      getPopupContainer={() => getPopupContainer?.() || document.body}
      components={{
        button: UiDatePickerOkButton,
      }}
      {...datePickerFormatProps[formatType]}
      {...restProps}
      showNow={false}
    />
  );
};
