import classNames from 'classnames';
import React, { FC } from 'react';

import RemoveSvg from '@vkph/ui/svg/remove.svg';

import { UiIcon } from '../../icon';
import { UiDatePickerFormatType, UiDatePickerFormatTypeProps } from '../UiDatePicker';
import { UiDatePickerClearIcon } from '../clear-icon/UiDatePickerClearIcon';
import { DateFnsPicker } from '../date-fns-picker/DateFnsPicker';
import { UiDatePickerOkButton } from '../ok-button/UiDatePickerOkButton';
import styles from './UiDatePickerRange.scss';
import { UiDatePickerRangeInput } from './input/UiDatePickerRangeInput';

type DateFnsPickerRangePickerProps = typeof DateFnsPicker.RangePicker.defaultProps;
export interface UiDatePickerRangeBaseProps extends UiDatePickerFormatTypeProps {
  hasFullWidth?: boolean;
}

export type UiDatePickerRangeProps = Omit<UiDatePickerRangeBaseProps, 'showNow'> &
  DateFnsPickerRangePickerProps;

const datePickerRangeFormatProps: Record<UiDatePickerFormatType, UiDatePickerRangeProps> = {
  date: {
    format: 'dd.MM.yyyy',
    placeholder: ['Начальная дата', 'Конечная дата'],
  },
  dateTime: {
    format: 'dd.MM.yyyy HH:mm',
    showTime: { format: 'HH:mm' },
    placeholder: ['Начальные дата и время', 'Конечные дата и время'],
  },
  dateYear: {
    picker: 'year',
    format: 'yyyy',
    placeholder: ['Начальный год', 'Год окончания'],
  },
};

export const UiDatePickerRange: FC<UiDatePickerRangeProps> = (props) => {
  const {
    size,
    formatType = 'date',
    className,
    hasFullWidth = false,
    placeholder: placeholderProps,
    ...rest
  } = props;

  const pickerClassName = classNames(
    styles.uiDatePickerRange,
    {
      [styles.uiDatePickerRange__fullWidth]: hasFullWidth,
    },
    className,
  );

  return (
    <DateFnsPicker.RangePicker
      suffixIcon={null}
      className={pickerClassName}
      allowClear={{ clearIcon: UiDatePickerClearIcon }}
      separator={<UiIcon component={RemoveSvg} width={20} height={20} />}
      components={{
        input: UiDatePickerRangeInput,
        button: UiDatePickerOkButton,
      }}
      {...datePickerRangeFormatProps[formatType]}
      {...rest}
      showNow={false}
    />
  );
};
