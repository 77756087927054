import Icon from '@ant-design/icons';
import React, { useMemo, ComponentProps, FC, ComponentType, SVGProps } from 'react';

import type { UiCustomIconComponentProps } from '.';

export interface UiIconProps extends ComponentProps<typeof Icon> {
  fill?: UiCustomIconComponentProps['fill'];
}

type CustomIconStyledComponent = ComponentType<UiCustomIconComponentProps | SVGProps<SVGSVGElement>>;

export const UiIcon: FC<UiIconProps> = (props) => {
  const { width = '1em', height = '1em', fill, color, component: Component, ...iconProps } = props;
  const svgProps = fill ? { fill, color } : { color };

  const CustomComponent = useMemo<CustomIconStyledComponent | undefined>(() => {
    return Component
      ? ({ className, style }) => (
          // @ts-expect-error обязательный prop в antd: fill="none", в наших svg по умолчанию: fill="currentColor"
          <Component className={className} style={style} width={width} height={height} {...svgProps} />
        )
      : undefined;
  }, [Component, height, width, svgProps]);

  return <Icon {...iconProps} component={CustomComponent} />;
};
