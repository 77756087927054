import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import { UiTypography, UiTypographyTitleProps } from '../../../typography';
import styles from './UiCardHeaderTitle.scss';

export interface UiCardHeaderTitleProps extends UiTypographyTitleProps {
  icon?: ReactNode;
  className?: string;
}

export const UiCardHeaderTitle: FC<UiCardHeaderTitleProps> = (props) => {
  const { icon, children, className, ...rest } = props;
  const classNameStyle = classNames(className, styles.uiCardHeaderTitle, {
    [styles.uiCardHeaderTitle__icon]: icon,
  });

  return (
    <>
      {icon}
      <UiTypography.Title level={4} bottom={0} className={classNameStyle} {...rest}>
        {children}
      </UiTypography.Title>
    </>
  );
};
