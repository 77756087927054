import classNames from 'classnames';
import React, { FC } from 'react';

import { UiSpace } from '../space';
import { UiTypography, UiTypographyBaseType } from '../typography';
import styles from './UiStatCell.scss';
import { UiStatCellFrame, UiStatCellFrameProps } from './frame/UiStatCellFrame';

export interface UiStatCellProps extends UiStatCellFrameProps {
  title: string;
  value: string;
  subtitle?: string;
  strong?: boolean;
  type?: UiTypographyBaseType;
}

type UiStatCellComponentProps = {
  Frame: typeof UiStatCellFrame;
};

export const UiStatCell: UiStatCellComponentProps & FC<UiStatCellProps> = (props) => {
  const { strong, title, value, subtitle, border, type } = props;

  return (
    <UiStatCellFrame className={styles.uiStatCell} border={border}>
      <UiTypography.Text
        strong={strong}
        type="secondary"
        className={classNames(styles.uiStatCell__title, {
          [styles.uiStatCell__title_light]: !strong,
        })}
      >
        {title}
      </UiTypography.Text>
      <UiSpace wrap size={8} align="baseline" className={styles.uiStatCell__subtitle}>
        <UiTypography.Title level={3} type={type}>
          {value}
        </UiTypography.Title>
        {subtitle && (
          <UiTypography.Title level={5} type={type}>
            {subtitle}
          </UiTypography.Title>
        )}
      </UiSpace>
    </UiStatCellFrame>
  );
};

UiStatCell.Frame = UiStatCellFrame;
