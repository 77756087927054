import { DependencyList, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export const useElementSize = <T extends HTMLElement = HTMLDivElement>(dependencies: DependencyList = []) => {
  const ref = useRef<T>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const onResizeDebounced = useDebouncedCallback(() => {
    const refWidth = ref.current?.clientWidth || 0;
    const refHeight = ref.current?.clientHeight || 0;

    setWidth(refWidth);
    setHeight(refHeight);
  }, 300);

  useEffect(() => {
    if (!ref.current) return undefined;

    const resizeObserver = new ResizeObserver(onResizeDebounced);

    resizeObserver.observe(ref.current);

    return () => resizeObserver.disconnect();
  }, dependencies);

  return { ref, width, height };
};
