import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import classNames from 'classnames';
import React, { forwardRef } from 'react';

import { SpacesType } from '../../../providers/space';
import styles from './UiTypographyTitle.scss';

type SpacesKeys = keyof SpacesType<number>;
type SpacesValues = SpacesType[SpacesKeys];

export interface UiTypographyTitleProps extends TitleProps {
  bottom?: SpacesValues;
}

export const UiTypographyTitle = forwardRef<HTMLHeadingElement, UiTypographyTitleProps>((props, ref) => {
  const { level = 1, bottom, children, style, className, ...restProps } = props;

  return (
    <Typography.Title
      ref={ref}
      {...restProps}
      level={level}
      style={{ marginBottom: bottom, ...style }}
      className={classNames(styles.uiTypographyTitle, className)}
    >
      {children}
    </Typography.Title>
  );
});
