import type { DisabledTimes } from 'rc-picker/lib/interface';
import type { FC } from 'react';

import { UiDatePicker as DatePicker, UiDatePickerProps } from './UiDatePicker';
import { UiDatePickerRange } from './range/UiDatePickerRange';

export * from './UiDatePicker';
export type { UiDatePickerRangeProps } from './range/UiDatePickerRange';
export type UiDatePickerDisabledTimes = DisabledTimes;

type UiDatePickerComposition = {
  RangePicker: typeof UiDatePickerRange;
};

export const UiDatePicker = DatePicker as FC<UiDatePickerProps> & UiDatePickerComposition;

UiDatePicker.RangePicker = UiDatePickerRange;
