import { List } from 'antd';
import { ListItemProps, ListItemMetaProps } from 'antd/lib/list';
import classNames from 'classnames';
import React, { CSSProperties, FC, forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';

import styles from './UiListItem.scss';

export interface UiListItemProps extends ListItemProps {
  noStyle?: boolean;
  hoverable?: boolean;
}
export type UiListItemMetaComponent = FC<ListItemMetaProps>;
export type UiListComposition = {
  Meta: UiListItemMetaComponent;
};

export type UiListItemComponent = ForwardRefExoticComponent<UiListItemProps & RefAttributes<HTMLDivElement>>;

const UiListItemBase: UiListItemComponent = forwardRef((props, ref) => {
  const { onClick, className, style, noStyle, hoverable, ...restProps } = props;
  const itemClassnames = classNames(className, {
    [styles.uiListItem_clickable]: Boolean(onClick),
    [styles.uiListItem_hoverable]: hoverable ?? Boolean(onClick),
  });

  const mergedStyle: CSSProperties = {
    ...style,
    ...(noStyle && { all: 'unset' }),
  };

  return (
    <List.Item ref={ref} onClick={onClick} className={itemClassnames} style={mergedStyle} {...restProps} />
  );
});

export const UiListItem = UiListItemBase as UiListItemComponent & UiListComposition;

UiListItem.Meta = List.Item.Meta;
