import { createContext } from 'react';

import { UiButtonProps } from '../button';
import { UiToggleButtonProps } from './UiToggleButton';

export type ToggleButtonGroupValue = unknown;

export type ToggleButtonParams = Omit<
  Exclude<UiToggleButtonProps, UiButtonProps>,
  'onChange' | 'defaultValue' | 'label' | 'value'
>;

export interface ToggleButtonGroupContextValue extends Pick<UiToggleButtonProps, 'onChange'> {
  value?: ToggleButtonGroupValue;
  toggleButtonParams?: ToggleButtonParams;
}

export const ToggleButtonGroupContext = createContext<ToggleButtonGroupContextValue>({});
