import React, { FC } from 'react';

import { UiTypography } from '../../typography';

type Props = {
  total: number;
  pageSize: number;
};

export const UiPaginationTotal: FC<Props> = (props) => {
  const { total, pageSize } = props;

  return (
    <UiTypography.Text>
      из <UiTypography.Text strong>{Math.ceil(total / pageSize)}</UiTypography.Text>
    </UiTypography.Text>
  );
};
