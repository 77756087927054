import classNames from 'classnames';
import React, { MouseEvent, ReactNode, useEffect, useContext, FC } from 'react';

import { useToggle } from '../../hooks';
import { UiButton, UiButtonProps } from '../button';
import { UiSelectDefaultOption } from '../select';
import { UiTooltip, UiTooltipProps } from '../tooltip';
import styles from './UiToggleButton.scss';
import { ToggleButtonGroupContext, ToggleButtonGroupValue } from './context';
import { UiToggleButtonGroup } from './group';

interface UiToggleButtonsOption
  extends UiSelectDefaultOption<ToggleButtonGroupValue>,
    Pick<UiButtonProps, 'icon'> {
  tooltipLabel?: UiTooltipProps['title'];
}

type UiToggleButtonComposition = {
  Group: typeof UiToggleButtonGroup;
};

export interface UiToggleButtonProps
  extends Partial<Omit<UiButtonProps, 'label' | 'defaultValue' | 'onChange' | 'value'>> {
  value?: ToggleButtonGroupValue;
  options?: UiToggleButtonsOption[];
  label?: ReactNode;
  tooltipLabel?: UiToggleButtonsOption['tooltipLabel'];
  defaultValue?: ToggleButtonGroupValue;
  onChange?: (value?: ToggleButtonGroupValue, event?: MouseEvent) => void;
}

export const UiToggleButton: FC<UiToggleButtonProps> & UiToggleButtonComposition = (props) => {
  const {
    options,
    label,
    onChange,
    children,
    className,
    defaultValue,
    tooltipLabel,
    value,
    ...toggleButtonProps
  } = props;

  const {
    value: valueContext,
    onChange: onChangeContext,
    toggleButtonParams,
  } = useContext(ToggleButtonGroupContext);

  const [nextValue, setNextValue] = useToggle(options || []);

  const onSelect = (event?: MouseEvent) => {
    const updateValue = value === valueContext ? undefined : value;

    onChangeContext?.(updateValue, event);
  };

  const onNextValue = (event?: MouseEvent) => {
    setNextValue();
    onChange?.(nextValue.value, event);
  };

  useEffect(() => {
    if (defaultValue && options) {
      const nextVal = options.find((item) => item.value === defaultValue);

      setNextValue(nextVal);
    }
  }, []);

  if (options) {
    return (
      <UiTooltip title={nextValue.tooltipLabel || tooltipLabel}>
        <UiButton {...toggleButtonProps} icon={nextValue.icon} className={className} onClick={onNextValue}>
          {nextValue.label || label}
        </UiButton>
      </UiTooltip>
    );
  }

  return (
    <UiTooltip title={tooltipLabel}>
      <UiButton
        {...toggleButtonParams}
        {...toggleButtonProps}
        className={classNames(className, styles.uiToggleButtons, {
          [styles.uiToggleButtons_selected]: value === valueContext,
          [styles.uiToggleButtons_disabled]: toggleButtonParams?.disabled || toggleButtonProps?.disabled,
        })}
        onClick={onSelect}
      >
        {label || children}
      </UiButton>
    </UiTooltip>
  );
};

UiToggleButton.Group = UiToggleButtonGroup;
