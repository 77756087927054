import React, { FC } from 'react';

import { UiButton, UiButtonProps } from '../../button';
import { UiSpace } from '../../space';

export const UiDatePickerOkButton: FC<UiButtonProps> = (props) => {
  const { children, ...btnProps } = props;

  return (
    <UiSpace>
      <UiButton {...btnProps} type="link" size="small" label="Применить" />
    </UiSpace>
  );
};
