import React, { forwardRef, ReactNode, FC, PropsWithChildren, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { UiDrawer, UiDrawerProps } from '../../../drawer';
import { UiTypography } from '../../../typography';
import styles from '../UiImagePreview.scss';

export interface UiImagePreviewGroupSiderProps extends UiDrawerProps {
  placement?: 'left' | 'right';
  content?: ReactNode;
  visible?: boolean;
  toggleVisibility?: () => void;
}

export interface UiImageCustomContentPreviewProps extends UiImagePreviewGroupSiderProps {
  parentElement?: HTMLDivElement | null;
}

export const UiImageCustomContentPreview: FC<
  PropsWithChildren<UiImageCustomContentPreviewProps> & React.RefAttributes<HTMLDivElement>
> = forwardRef<HTMLDivElement, PropsWithChildren<UiImageCustomContentPreviewProps>>((props, ref) => {
  const { children, parentElement, ...siderProps } = props;

  const {
    visible,
    toggleVisibility,
    placement = 'right',
    width = 460,
    title: siderTitle,
    content,
    ...restSiderProps
  } = siderProps || {};

  const getTitle = useMemo(
    () => (title: UiImagePreviewGroupSiderProps['title']) =>
      typeof title === 'string' ? (
        <UiTypography.Title level={4} bottom={0}>
          {title}
        </UiTypography.Title>
      ) : (
        title
      ),
    [],
  );

  return (
    <>
      {children}
      {createPortal(
        <div ref={ref} style={{ [styles.uiImagePreviewSidebarWidth]: `${width}px` }}>
          {parentElement && (
            <UiDrawer
              title={getTitle(siderTitle)}
              placement={placement}
              onClose={toggleVisibility}
              open={visible}
              getContainer={parentElement || false}
              width={width}
              mask={false}
              bodyStyle={{ padding: 0 }}
              {...restSiderProps}
            >
              {content}
            </UiDrawer>
          )}
        </div>,
        document.body,
      )}
    </>
  );
});
