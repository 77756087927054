import React, { forwardRef, useContext } from 'react';

import CalendarSvg from '@vkph/ui/svg/calendar.svg';

import { UiConfigProviderSizeContext } from '../../../config-provider';
import { UiIcon } from '../../../icon';
import { UiInput, UiInputComponent, UiInputProps } from '../../../input';

export const UiDatePickerRangeInput: UiInputComponent = forwardRef((props, ref) => {
  const providerSize = useContext(UiConfigProviderSizeContext);
  const inputProps: UiInputProps = {
    allowClear: false,
    size: providerSize || 'large',
    suffix: <UiIcon component={CalendarSvg} width={20} height={20} />,
  };

  return <UiInput {...props} {...inputProps} ref={ref} />;
});
