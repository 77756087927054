import classNames from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren, ReactNode } from 'react';

import { UiButton } from '../../button';
import { UiOverlayProps } from '../UiOverlay';
import styles from '../UiOverlay.scss';

export interface UiOverlayButtonProps
  extends Pick<UiOverlayProps, 'trigger' | 'isVisible'>,
    HTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  elements?: ReactNode;
}

export const UiOverlayButton: FC<PropsWithChildren<UiOverlayButtonProps>> = (props) => {
  const { style, elements, children, onClick, trigger, className, isVisible = true } = props;

  return (
    <UiButton.Decorator
      className={classNames(styles.uiOverlay, styles.uiOverlayButton, className, {
        [`${styles.uiOverlay}_${trigger}`]: trigger,
        [styles.uiOverlay_appearance]: trigger,
      })}
      onClick={onClick}
      style={style}
    >
      {children}
      <span
        className={classNames(styles.uiOverlay__overlay, {
          [styles.uiOverlay__overlay_visible]: isVisible,
        })}
      >
        {elements}
      </span>
    </UiButton.Decorator>
  );
};
