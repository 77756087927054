import { Tooltip as AntTooltip } from 'antd';
import type { TooltipRef, TooltipPlacement, TooltipPropsWithTitle } from 'antd/lib/tooltip';
import classNames from 'classnames';
import React, { forwardRef } from 'react';

import { UiConfigProviderSizeType } from '../config-provider';
import styles from './UiTooltip.scss';

export type UiTooltipPlacementProps = TooltipPlacement;
export type UiTooltipRef = TooltipRef;
export type UiTooltipRadiusSize = UiConfigProviderSizeType;

export enum UiTooltipPlacement {
  Bottom = 'bottom',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
  Left = 'left',
  LeftBottom = 'leftBottom',
  LeftTop = 'leftTop',
  Right = 'right',
  RightBottom = 'rightBottom',
  RightTop = 'rightTop',
  Top = 'top',
  TopLeft = 'topLeft',
  TopRight = 'topRight',
}

const getAlignTargetOffset = (placement: UiTooltipPlacementProps = UiTooltipPlacement.Top, shift = 2) => {
  const mapTargetOffset: Record<UiTooltipPlacement, [number, number]> = {
    [UiTooltipPlacement.Bottom]: [0, shift],
    [UiTooltipPlacement.BottomLeft]: [0, shift],
    [UiTooltipPlacement.BottomRight]: [0, shift],
    [UiTooltipPlacement.Left]: [-shift, 0],
    [UiTooltipPlacement.LeftBottom]: [-shift, 0],
    [UiTooltipPlacement.LeftTop]: [-shift, 0],
    [UiTooltipPlacement.Right]: [shift, 0],
    [UiTooltipPlacement.RightBottom]: [shift, 0],
    [UiTooltipPlacement.RightTop]: [shift, 0],
    [UiTooltipPlacement.Top]: [0, -shift],
    [UiTooltipPlacement.TopLeft]: [0, -shift],
    [UiTooltipPlacement.TopRight]: [0, -shift],
  };

  return mapTargetOffset[placement];
};

export interface UiTooltipProps extends TooltipPropsWithTitle {
  placement?: UiTooltipPlacementProps;
  shift?: number;
  radiusSize?: UiTooltipRadiusSize;
  emptyPadding?: boolean;
}

const UiTooltipInternal = forwardRef<UiTooltipRef, UiTooltipProps>((props, ref) => {
  const {
    destroyTooltipOnHide = true,
    shift = 2,
    placement,
    radiusSize = 'middle',
    emptyPadding,
    overlayClassName,
  } = props;
  const align = { targetOffset: getAlignTargetOffset(placement, shift) };

  const styleClassNames = {
    [`${styles.uiTooltip__overlay_radius}-${radiusSize}`]: radiusSize,
    [styles.uiTooltip__overlay_emptyPadding]: emptyPadding,
  };

  return (
    <AntTooltip
      ref={ref}
      align={align}
      mouseEnterDelay={0.3}
      destroyTooltipOnHide={destroyTooltipOnHide}
      {...props}
      overlayClassName={classNames(styles.uiTooltip__overlay, styleClassNames, overlayClassName)}
    />
  );
});

export const UiTooltip = UiTooltipInternal as typeof UiTooltipInternal;
