import { App } from 'antd';
import { updateCSS } from 'rc-util/lib/Dom/dynamicCSS';
import React, { useMemo, useCallback, useEffect, useRef, PropsWithChildren, FC, createContext } from 'react';

import tokens from '@vkph/tokens';

import { UiConfigProvider } from '../../components/config-provider';
import { getAntTheme } from './getAntTheme';
import { themeOptionsDefault } from './themeOptionsDefault';
import { ThemeState, ThemeOptions, ThemeProviderProps } from './types';

type ThemeAction = (state: ThemeOptions) => void;

type ThemeContextValueType = [ThemeState, ThemeAction];

const ThemeContext = createContext<ThemeContextValueType>([themeOptionsDefault, (_: ThemeOptions) => {}]);

export const ThemeProvider: FC<PropsWithChildren<ThemeProviderProps>> = (props) => {
  const { children, theme, renderEmpty, onChange } = props;
  const dynamicStyleMark = `${theme.varsPrefix}`;

  const updateCSSVariablesRoot = ({ variables }: ThemeState) => {
    const cssVariablesList = Object.keys(variables).map(
      (key) => `${theme.varsPrefix}-${key}: ${variables[key]};`,
    );

    const tokensList = Object.keys(tokens).map((key) => `--${key}: ${tokens[key]};`);

    const mergedVariables = [...cssVariablesList, ...tokensList];

    updateCSS(`:root {${mergedVariables.join('\n')}}`, dynamicStyleMark);
  };

  const onChangeRef = useRef(onChange);

  onChangeRef.current = onChange;

  const setTheme = useCallback((value: ThemeOptions) => {
    updateCSSVariablesRoot(value);

    if (onChangeRef.current) {
      onChangeRef.current(value);
    }
  }, []);

  useEffect(() => {
    updateCSSVariablesRoot(theme);
  }, [theme]);

  const value: ThemeContextValueType = useMemo(() => {
    const mergedVariables = { ...theme.variables, ...tokens };

    return [{ ...theme, variables: mergedVariables }, setTheme];
  }, [theme, setTheme]);

  const antTheme = useMemo(() => {
    return getAntTheme(theme);
  }, [theme]);

  return (
    <UiConfigProvider theme={antTheme} renderEmpty={renderEmpty}>
      <ThemeContext.Provider value={value}>
        <App component={false}>{children}</App>
      </ThemeContext.Provider>
    </UiConfigProvider>
  );
};

export const useTheme = (): [ThemeOptions, ThemeAction] => {
  const context = React.useContext(ThemeContext);

  if (!context) {
    throw new Error('Can not resolve ThemeContext into useTheme hook');
  }

  return context;
};
