import classNames from 'classnames';
import React, { useRef, useMemo, useState } from 'react';

import AddSVG from '@vkph/ui/svg/add.svg';

import { BaseDataType, OptionModel } from '../../types/option';
import { UiAutoComplete, UiAutoCompleteProps } from '../auto-complete';
import { UiButton } from '../button';
import { UiOptionData, UiBaseSelectRef } from '../select';
import styles from './UiMultiSelect.scss';
import { UiMultiSelectItem, UiMultiSelectItemProps } from './item/UiMultiSelectItem';

const uiMultiSelectClassName = 'ui-multi-select';

export type UiMultiSelectItemId = string;

export type UiMultiSelectItemType<
  ValueType extends UiMultiSelectItemId = UiMultiSelectItemId,
  DataType extends BaseDataType = BaseDataType,
> = UiMultiSelectItemProps<ValueType, DataType>;

export interface UiMultiSelectProps<
  ValueType extends UiMultiSelectItemId = UiMultiSelectItemId,
  DataType extends BaseDataType = BaseDataType,
> extends Omit<UiAutoCompleteProps, 'options'> {
  items: OptionModel<ValueType, DataType>[];
  onItemsChange?: (itemsValues: ValueType[]) => void;
  onItemAdd?: (option: OptionModel<ValueType, DataType>) => void;
  onItemRemove?: (id: ValueType) => void;
  ItemContent?: (props: Omit<UiMultiSelectItemType<ValueType, DataType>, 'ItemContent'>) => JSX.Element;
  className?: string;
  containerClassName?: string;
  autoCompleteClassName?: string;
  disabled?: boolean;
  maxItemsCount?: number;
  options?: OptionModel<ValueType, DataType>[];
}

export const UiMultiSelect = <
  ValueType extends UiMultiSelectItemId = UiMultiSelectItemId,
  DataType extends BaseDataType = BaseDataType,
>(
  props: UiMultiSelectProps<ValueType, DataType>,
) => {
  const {
    className,
    items = [],
    onItemsChange,
    onItemAdd,
    onItemRemove,
    ItemContent,
    options = [],
    disabled,
    maxItemsCount,
    onSearch,
    size,
    onBlur,
    containerClassName,
    autoCompleteClassName,
    maxLength,
    ...autoCompleteProps
  } = props;

  const inputRef = useRef<UiBaseSelectRef>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const autoCompleteRef = useRef<HTMLDivElement>(null);
  const [autoCompleteValue, setAutoCompleteValue] = useState('');

  const [selectOpen, setSelectOpen] = useState<boolean | undefined>(undefined);
  const dropDownWidth = wrapperRef.current?.offsetWidth;

  const autoCompleteOffset = autoCompleteRef.current?.getBoundingClientRect()?.x || 0;
  const wrapperOffset = wrapperRef.current?.getBoundingClientRect()?.x || 0;
  const dropdownOffset = [wrapperOffset - autoCompleteOffset, 8];

  const onFocusHandler = () => {
    if (inputRef.current && !disabled) {
      inputRef.current.focus();
      setSelectOpen(true);
    }
  };

  const onSelectHandler = (_value: UiOptionData, { value }: UiOptionData) => {
    if (onItemsChange) {
      const itemsValues = items.map((item) => item.value);

      onItemsChange([...itemsValues, value as ValueType]);
    }

    if (onItemAdd) {
      const addedOption = options?.find((option) => option.value === value);

      if (addedOption) {
        onItemAdd(addedOption);
      }
    }

    if (onSearch) {
      onSearch('');
    }

    setAutoCompleteValue('');
    setSelectOpen(false);
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLElement>) => {
    if (onBlur) {
      onBlur(event);
    }

    setSelectOpen(false);
  };

  const onChangeHandler = (value: string | number) => {
    if (typeof value === 'string') {
      setAutoCompleteValue(value?.trimStart());
    }

    setSelectOpen(value !== '');
  };

  const placeholder = items.length > 0 ? undefined : 'Добавить';
  const isDisableAdding = Boolean(maxItemsCount && items.length >= maxItemsCount);

  const onRemove = (itemId: ValueType) => {
    if (onItemsChange) {
      const itemsValues = items.map(({ value }) => value);
      const filteredItems = itemsValues?.filter((value) => value !== itemId);

      onItemsChange(filteredItems);
    }

    if (onItemRemove) {
      onItemRemove(itemId);
    }
  };

  const elements = items.map((item) => (
    <UiMultiSelectItem<ValueType, DataType>
      key={item.value}
      onRemove={onRemove}
      ItemContent={ItemContent}
      isDisabled={disabled}
      {...item}
    />
  ));

  const filteredOptions = useMemo(() => {
    const selectedOptionsSet = new Set(items.map(({ value }) => value));

    return options.filter((option) => !selectedOptionsSet.has(option.value));
  }, [options, items]);

  const autoCompleteOptions: UiOptionData[] =
    filteredOptions.map(({ value, data }) => ({
      value,
      label: data.label,
    })) || [];

  return (
    <div
      ref={wrapperRef}
      role="textbox"
      tabIndex={-1}
      className={classNames(uiMultiSelectClassName, styles.uiMultiSelect, containerClassName, {
        [styles.uiMultiSelect_disabled]: disabled,
        [styles.uiMultiSelect_large]: size === 'large',
      })}
    >
      {elements}
      <div ref={autoCompleteRef} className={classNames(styles.uiMultiSelect__input, className)}>
        <UiAutoComplete
          open={selectOpen}
          ref={inputRef}
          placeholder={placeholder}
          disabled={disabled || isDisableAdding}
          onFocus={onFocusHandler}
          onSelect={onSelectHandler}
          onChange={onChangeHandler}
          value={autoCompleteValue}
          options={autoCompleteOptions}
          onSearch={onSearch}
          onBlur={onBlurHandler}
          dropdownStyle={{ minWidth: dropDownWidth }}
          dropdownAlign={{ offset: dropdownOffset }}
          className={classNames(styles.uiMultiSelect__autoComplete, autoCompleteClassName)}
          maxLength={maxLength}
          size={size}
          {...autoCompleteProps}
        />
      </div>
      <UiButton
        type="link"
        onClick={onFocusHandler}
        className={styles.uiMultiSelect__plusIcon}
        icon={<AddSVG />}
        disabled={disabled || isDisableAdding}
        size="middle"
      />
    </div>
  );
};
